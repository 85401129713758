import React, { Component } from "react";
import { ListGroup, Container, Button } from "react-bootstrap";
import ContactForm from "./contact-form";
import Footer from "./footer";
import { Download } from "react-bootstrap-icons";
import styled from "styled-components";

import FBCPdf1 from "./Fluke-User-Manuals/177-efsp-datasheet.pdf";
import FBCPdf2 from "./Fluke-User-Manuals/DVM177-User-Manual.pdf";
import FBCPdf3 from "./Fluke-User-Manuals/ESA612-Datasheet.pdf";
import FBCPdf4 from "./Fluke-User-Manuals/ESA612-Getting-Started-Manual.pdf";
import FBCPdf5 from "./Fluke-User-Manuals/ESA612-User-Manual.pdf";
//import ISOCert from "./msds/ISO13485CertificateofRegistration.pdf";
import MotorShieldPDF from "./HSMotorShield/H&SMotorShieldInstallationInstructions.pdf";
import ValveAdjustmentPDF from "./msds/H&SValveRebuildInstructions.pdf";
import BBRAUNCatalog from "./msds/catalogs/HSNipro_BBRAUN_Catalog_20230216_RevA.pdf";
import CalibrationSolutionsCatalog from "./msds/catalogs/HSNipro_Solutions_Catalog_20240711.pdf";
import FreseniusCatalog from "./msds/catalogs/HSNipro_Fresenius_Catalog_20230117.pdf";
import GambroCatalog from "./msds/catalogs/HSNipro_Gambro_Catalog_20231117.pdf";
import MeterCalCatalog from "./msds/catalogs/HSNipro_Calibration_Catalog_20231214.pdf";
import ProductCatalog from "./msds/catalogs/HSNipro_Exclusive_Products_Catalog_20230110.pdf";
import Genius614Marketing from "./msds/HS_Nipro_Genius_614_Marketing_Flyer_040424_RevB.pdf";

import SymbolsGlossary from "./policies/SM_FRM_3000F2_RevA.pdf"

import TDS442 from "./msds/442TDSConductivityStandardSolutionMaterialSafetyDataSheet.pdf";
import ConductivitySolutionNaCI from "./msds/ConductivitySolutionNaCIMaterialSafetyDataSheet.pdf";
import HSCellCleaningSolution from "./msds/HSCellCleaningSolutionMaterialSafetyDataSheet.pdf";
import KCITDS from "./msds/KCITDSConductivityStandardSolutionMaterialSafetyDataSheet.pdf";
import PH401 from "./msds/pH4-01-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH70 from "./msds/pH7-0-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH825 from "./msds/pH8-25-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH10 from "./msds/pH10-0-BufferSolutionMaterialSafetyDataSheet.pdf";

const StyledDownload = styled(Download)`
  margin-right: 20px;
`;
const StyledContainer = styled(Container)`
  margin-top: 75px;
`;

class downloadsPage extends Component {
  render() {
    return (
      <div>
        <div class="jumbotron">
          <StyledContainer>
            <h2>Product & Pricing Information</h2>
            <br></br>
            <ListGroup>
              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
                NIPRO Technical Solutions Parts Catalogs
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={ProductCatalog + "?pdf=product%2catalog%20230110"}
                  download
                ><StyledDownload size={20} />
                  Exclusive Product Catalog_20230110
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={FreseniusCatalog + "?pdf=fresenius%2catalog%20230117"}
                  download
                ><StyledDownload size={20} />
                  Fresenius Catalog_20230117
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={
                    MeterCalCatalog + "?pdf=meter%2calibration%2catalog%20231214"
                  }
                  download
                ><StyledDownload size={20} />
              Meter Calibration Catalog_20231214
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={
                    CalibrationSolutionsCatalog +
                    "?pdf=calibration%2solutions%2catalog%20230110"
                  }
                  download
                ><StyledDownload size={20} />
                   Calibration Solutions Catalog_20240711
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={BBRAUNCatalog + "?pdf=bbraun%2catalog%2200131"}
                  download
                ><StyledDownload size={20} />
               BBRAUN Catalog_20230216
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={GambroCatalog + "?pdf=bbraun%2catalog%20231117"}
                  download
                ><StyledDownload size={20} />
                     Gambro Catalog_20231117
                </a>
              </ListGroup.Item>
              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
              NIPRO Technical Solutions Product Information
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  href={Genius614Marketing + "?pdf=H&S%2Genius614%2information"}
                  download
                ><StyledDownload size={20} />
                  Genius 614 Information
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={MotorShieldPDF + "?pdf=motor%2shield%2information"}
                  download
                ><StyledDownload size={20} />
                  Motor Shield Information
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={ValveAdjustmentPDF + "?pdf=valve%rebuild%instructions"}
                  download
                ><StyledDownload size={20} />
                  Valve Rebuild Instructions
                </a>
              </ListGroup.Item> 

              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
              NIPRO Technical Solutions Calibration
                        Reference Solution Safety Information
              </ListGroup.Item>
              <ListGroup.Item>
                        <a
                          href={SymbolsGlossary}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Solution Symbols Glossary
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={TDS442}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          442 TDS
                          Conductivity Standard Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={HSCellCleaningSolution}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cell Cleaning
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={ConductivitySolutionNaCI}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Conductivity
                          Solution NaCI Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={KCITDS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          KCI TDS
                          Conductivity Standard Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH401}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          pH 4.01 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH70}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          pH 7.0 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH825}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          pH 8.25 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH10}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          pH 10.0 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>



              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>User Manuals</ListGroup.Item>
              <ListGroup.Item>
                <b>Fluke Biomedical:</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={FBCPdf4 + "?pdf=fluke_esa_612_getting_started_manual"}
                  download
                >
                  <StyledDownload size={20} />
                  Fluke ESA 612 Getting Started Manual
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={FBCPdf5 + "?pdf=fluke_esa_612_user_manual"} download>
                  <StyledDownload size={20} />
                  Fluke ESA 612 User Manual
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={FBCPdf2 + "?pdf=fluke_dvm_177_user_manual"} download>
                  <StyledDownload size={20} />
                  Fluke DVM 177 User Manual
                </a>
              </ListGroup.Item>

              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
                Specification Data
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Fluke Biomedical:</b>
              </ListGroup.Item>

              <ListGroup.Item>
                <a href={FBCPdf3 + "?pdf=fluke_esa_612_data_sheet"} download>
                  <StyledDownload size={20} />
                  Fluke ESA 612 Data Sheet
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={FBCPdf1 + "?pdf=fluke_177_efsp_data_shee"} download>
                  <StyledDownload size={20} />
                  Fluke 177 EFSP Data Sheet
                </a>
              </ListGroup.Item>
{/*
              <ListGroup.Item variant="primary">Certifications</ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={ISOCert + "?pdf=iso_13485_certificate_of_registration"}
                  download
                >
                  <StyledDownload size={20} />
                  ISO 13485 Certificate of Registration
                </a>
              </ListGroup.Item>
                */}
            </ListGroup>
            <em class="italicsFootnote">*Prices are subject to change.</em>
          </StyledContainer>
        </div>
        <ContactForm />
        <Footer />
      </div>
    );
  }
}

export default downloadsPage;
