import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Col } from "react-bootstrap";
import styled from "styled-components";

const StyledDiv = styled(animated.div)`
  max-width: 100%;
  overflow: hidden;
  positon: absolute;
`;

const TextScroller = ({ text }) => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(60%,0)" },
    to: { transform: "translate(-60%,0)" },
    config: { duration: 10000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <Col sm={12} lg={12} key={key} className="landingBanner landingPosition">
      <StyledDiv style={scrolling}>{text}</StyledDiv>
    </Col>
  );
};

export default TextScroller;
