import React, { Component } from "react";
import { Col, Button, Row } from "react-bootstrap";
import quality from "./../images/hsm-revisions/QualityButtonStockImage.jpg";
import century from "./../images/hsm-revisions/SURDIAL_DX_ASN_Standalone_Shot_Edited_10152024_Cropped.jpg";
import CircuitWorkClose from "./../images/revisions/CircuitWorkCloseUp.jpg";
import CardioQuip from "./../images/hsm-revisions/Quality.jpg";
import repairDepot from "./../images/hsm-revisions/RepairServiceDepotImage.JPG";
import Nipro from "./../images/Nipro.jpg";

//content ported from hsmsvc.com

class LandingCardsD extends Component {
  render() {
    return (
      <Col lg={12}>
        <Row className="equal">
          <Col md={4} lg={4} className="LandingCardsCol">
            <h4>Engineering</h4>
            <div className="containingDivLandingD">
              <img
                src={CircuitWorkClose}
                className="landingCardDImg"
                alt="circuit board"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                  NIPRO Technical Solutions will meet all
                    your design needs, specializing in mechanical, electrical,
                    and manufacturing engineering. We help inventors, start-ups
                    and major manufacturers with prototypes and beyond.  {" "}
                  </p>
                  <Button variant="custom">
                    <a class="styledLink" href="engineering">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>

          <Col md={4} lg={4} className="LandingCardsCol">
            <h4>Turn-Key Manufacturing</h4>
            <div className="containingDivLandingD">
              <img
                src={century}
                className="landingCardDImg"
                alt="Century Equipment"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    We are the partner you need to get your idea to market. We
                    take care of everything from design to delivery. 
                  </p>
                  <Button variant="custom">
                    <a class="styledLink" href="turn-key-manufacturing">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="LandingCardsCol">
            <h4>Contract Manufacturing</h4>

            <div className="containingDivLandingD">
              <img
                src={CardioQuip}
                className="landingCardDImg"
                alt="Machinery"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    Our contract manufacturing services are available for small
                    run production to large scale manufacturing. If you can
                    dream it, we can build it!
                  </p>
                  <Button variant="custom">
                    <a class="styledLink" href="contract-manufacturing">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="equal">
          <Col md={4} lg={4} className="LandingCardsCol">
            <h4>Distribution & Logistics</h4>

            <div className="containingDivLandingD">
              <img
                src={Nipro}
                className="landingCardDImg"
                alt="Distribution Services"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    We get your products to your customers - FAST. Let{" "}
                    NIPRO Technical Solutions use our over 25 years of
                    experience in logistics to make it happen.
                  </p>
                  <Button variant="custom">
                    <a class="styledLink" href="distribution-logistics">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="LandingCardsCol">
            <h4>
              Quality Systems
            </h4>

            <div className="containingDivLandingD">
              <img
                src={quality}
                className="landingCardDImg"
                alt="Quality Servcice Engineer"
              />
              <i className="arrow up" />

              <div className="cardOverlay">
                <div className="landingCardDText">
                  <br />
                  <p>
                    At NIPRO Technical Solutions, quality is
                    ingrained into who we are. Let us use our over 25 years of experience
                    to seamlessly develop a quality system for you.
                  </p>
                  <Button variant="custom">
                    <a class="styledLink" href="quality-systems">Learn More</a>
                  </Button>
                </div>
              </div>
            </div>
          </Col>


        </Row>
      </Col>
    );
  }
}

export default LandingCardsD;
