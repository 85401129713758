import React, { Component } from "react";
import { Col, Card, Row, Button } from "react-bootstrap";
import RepairServices from "./images/newImages/Depot Electronics.jpg";
import TechSupport from "./images/techSupport.JPG";
import MotorShield from "./images/Genius_614_No_Background_Shadow_Added.png";
import CleaningService from "./images/RevisedImages/CalibrationLabUnstretched.jpg";
import SurdialDXAlt from "./images/SURDIAL_DX_Training_Mathew_Group_Edited_10152024_Cropped.jpg";
import Manufacturing from "./images/Manufacturing.jpg";

//this is actually the landing cards for mobile

class LandingCards extends Component {
  render() {
    return (
      <Col sm={12} xl={12}>
        <Row className="equal">
          <Col sm={12} md={4} lg={4} className="LandingCardsCol" >
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={RepairServices} className="img-responsive" />
              <Card.Body>
                <Card.Title>Hemodialysis Parts Service Center</Card.Title>
                <Card.Text>
                  NIPRO Technical Solutions offers a quick turnaround
                  time along with our signature 6-month, hassle-free warranty
                  policy for all services.
                </Card.Text>
                <Button variant="custom" href="/services" >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={CleaningService} className="img-responsive" />
              <Card.Body>
                <Card.Title>
                  Calibration, Solutions & Reference Meters
                </Card.Title>
                <Card.Text>
                  With fast turnaround times,
                  NIPRO Technical Solutions is your premiere provider
                  for calibrations, solutions and dialysate meters.
                </Card.Text>
                <Button variant="custom" href="/meters-calibration-solutions" >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={MotorShield} className="img-responsive" />
              <Card.Body>
                <Card.Title>
                  Biomedical Technician
                  Assist Devices{" "}
                </Card.Title>
                <Card.Text>
                NIPRO Technical Solutions is dedicated to helping
                  biomed technicians through innovative products such as the
                  Genius 614 Valve & Chamber Analyzer, BTA-612 Selectable
                  Voltage Power Supply, Motor Shields & Valve Repair Kits.
                </Card.Text>
                <Button href="/products" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="equal" id="equalRow">
          <Col sm={12} md={4} lg={4} className="LandingCardsCol ">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={TechSupport} className="img-responsive" />
              <Card.Body>
                <Card.Title>Technical Support</Card.Title>
                <Card.Text>
                  Speak with a highly skilled Technical Support expert to answer
                  all your troubleshooting questions. All calls are answered by
                  our friendly customer service representatives.
                </Card.Text>
                <Button href="/technical-support" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={SurdialDXAlt} className="img-responsive" />
              <Card.Body>
                <Card.Title>Field Service Engineering</Card.Title>
                <Card.Text>
                NIPRO Technical Solutions performs repairs and
                  annual maintenance on perfusion equipment and other machines
                  used in open heart surgery, servicing hospitals nationwide and
                  internationally. Specialized profusion equipment services
                  include Century, Sorin, Quest and Medtronic. {" "}
                </Card.Text>
                <Button href="/field-service-engineering" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={Manufacturing} className="img-responsive" />
              <Card.Body>
                <Card.Title>Manufacturing Services</Card.Title>
                <Card.Text>
                Our Manufacturing Service Center provides dedicated and customizable manufacturing services
                 for the global medical community.
                </Card.Text>
                <Button href="hsmanufacturing/manufacturing-services" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default LandingCards;
