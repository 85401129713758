import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HSMLanding from "./hsm-landing";
import HSMProducts from "./hsm-products";
import HSMServices from "./hsm-services";
import { Navigation } from "./nav-bar";
import HSMMCS from "./hsm-meters-calibration-solutions";
import TechSupport from "./tech-support";
import WhoWeAre from "./who-we-are";
import ContactPage from "./contact-page";
//import ContractManfacturing from "./contract-manufacturing";
import CareersPage from "./careers";
import PrivacyPolicy from "./privacy-policy";
import phone from "./images/phoneIcon.png";
import "./App.css";
import styled from "styled-components";
import FieldServiceEngineering from "./field-service-engineering";
import { EmailModal } from "./email-modal";

import DownloadsFormsPage from "./downloadsFormsPage";
import DownloadsPage from "./downloadsPage";

import SymbolsGlossary from "./symbolsglossary";
//from hsmsvc.com
import ContractManufacturing from "./hsmanufacturing/contract-manufacturing";
import DistributionLogistics from "./hsmanufacturing/distribution-logistics";
import Engineering from "./hsmanufacturing/engineering";
import ManufacturingServices from "./hsmanufacturing/manufacturing-services";
import QualitySystems from "./hsmanufacturing/quality-systems";
import RepairService from "./hsmanufacturing/repair-service";
import Turnkey from "./hsmanufacturing/turnkey-page";


const StyledImage = styled.img`
  max-width: 75px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 100%;
  background-color: white;
`;

const PhoneIcon = () => (
  <a href="tel:+18009232486" className="phone-button">
    <StyledImage src={phone} alt="phone icon - give us a call" />
  </a>
);

const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 200;
`;
class App extends Component {
  state = {
    showFormModal: false,
    showModal: false,
    showCovidModal: false,
    showEmailModal: false,
  };

  showEmailModal = () => {
    this.showModal
      ? this.setState({ showModal: false, showEmailModal: true })
      : this.setState({ showEmailModal: true });
  };

  hideEmailModal = () => {
    this.setState({ showEmailModal: false });
  };

  renderCovidModal = () => {
    this.setState({ showCovidModal: !this.state.showCovidModal });
  };
  render() {
    return (
      <div className="App">
        <div id="bodycontainer">
          <Navigation
            renderDownloadsModal={this.renderDownloadsModal}
            renderFormsDownloadModal={this.renderFormsDownloadModal}
            showMailingListModal={this.showEmailModal}
          />
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={<HSMLanding />}
                renderCovidModal={this.renderCovidModal}
              />
              <Route exact path="/services" element={<HSMServices />} />

              <Route exact path="/products" element={<HSMProducts />} />
              <Route
                exact
                path="/meters-calibration-solutions"
                element={<HSMMCS />}
              />
              <Route
                exact
                path="/technical-support"
                element={<TechSupport />}
              />
              <Route
                exact
                path="/field-service-engineering"
                element={<FieldServiceEngineering />}
              />
              <Route exact path="/who-we-are" element={<WhoWeAre />} />

              <Route
                exact
                path="/downloadsFormsPage"
                element={<DownloadsFormsPage />}
              />
              <Route exact path="/downloadsPage" element={<DownloadsPage />} />
              <Route exact path="/contact" element={<ContactPage />} />

              <Route exact path="/careers" element={<CareersPage />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/symbolsglossary" element={<SymbolsGlossary />} />

              <Route
                exact
                path="hsmanufacturing/manufacturing-services"
                element={<ManufacturingServices />}
              />
              <Route
                exact
                path="hsmanufacturing/contract-manufacturing"
                element={<ContractManufacturing />}
              />
              <Route
                exact
                path="hsmanufacturing/distribution-logistics"
                element={<DistributionLogistics />}
              />
              <Route
                exact
                path="hsmanufacturing/engineering"
                element={<Engineering />}
              />
              <Route
                exact
                path="hsmanufacturing/quality-systems"
                element={<QualitySystems />}
              />
              <Route
                exact
                path="hsmanufacturing/repair-service"
                element={<RepairService />}
              />
              <Route
                exact
                path="hsmanufacturing/turn-key-manufacturing"
                element={<Turnkey />}
              />
            </Routes>
          </BrowserRouter>

          {this.state.showEmailModal && (
            <EmailModal
              showModal={this.state.showEmailModal}
              close={this.hideEmailModal}
            />
          )}
        </div>

        <PhoneContainer>
          <PhoneIcon />
        </PhoneContainer>
      </div>
    );
  }
}

export default App;
