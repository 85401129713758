import React, {Component} from 'react';
import {Col} from 'react-bootstrap'

//content ported from hsmsvc.com

class LandingBanner extends Component{
    render(){
        return(
            <Col sm={12} xl={12} id={this.props.id} className="landingBanner">
            {this.props.text}
          </Col>

        )
    }
}

export default LandingBanner;