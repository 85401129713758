import React, { Component } from "react";
import ContactForm from "./contact-form";

import Footer from "./footer";
import { ListGroup, Container, Row } from "react-bootstrap";

import styled from "styled-components";
import { Download } from "react-bootstrap-icons";

import SymbolsGlossary from "./policies/SM_FRM_3000F2_RevA.pdf";


const StyledDownload = styled(Download)`
  margin-right: 20px;
`;

const StyledContainer = styled(Container)`
  margin-top: 75px;
`;

class symbolspage extends Component {
  render() {
    return (
      <div>
<div class="jumbotron">
          <StyledContainer>
            <br></br>
            <ListGroup>
              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
                {" "}
                Solution Symbols Glossary
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={
                    SymbolsGlossary}
                  download
                >
                  
                    <StyledDownload size={20} />

                  Download
                </a>
              </ListGroup.Item>

            </ListGroup>
          </StyledContainer>
        </div>
        <ContactForm />

        <Footer />
      </div>
    );
  }
}

export default symbolspage;
