import React, { Component, useState } from "react";
import { Row, Card, Accordion, Col, Jumbotron, Button } from "react-bootstrap";
import {Link} from "react-router-dom";
import ContactForm from "./../contact-form";
import Footer from "./../footer.js";
import ArtShipping from "./../images/ArtShipping.jpeg";
import Logistics from "./../images/RevisedImages/DSC02623.JPG";
import DistributionImg from "./../images/RevisedImages/Support.jpg";
import Business from "./../images/RevisedImages/DSC02520_Cropped.jpg";
import Support2020 from "./../images/support_2020.jpg";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import styled from "styled-components";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import LandingBanner from "./landing-banner";
import scrollToComponent from "react-scroll-to-component";
import QueryParamsDemo from "../scrollerComponent.js";
//content ported from hsmsvc.com with modifications

const ServicesText = (
  <p>
    {" "}
    If getting your product to the customer is complex, let{" "}
    NIPRO Technical Solutions be your first and only stop.
  </p>
);

const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}

class DistributionLogistics extends Component {
  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
    var fourthAccordionArrow = false;
    //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=logistics-expertise" )
      {
        return("logistics-expertise");
      }
      else if  (window.location.search == "?name=distribution-services" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        return("distribution-services");
      }
      else if  (window.location.search == "?name=engineering-support" )
      {
        firstAccordionArrow = false;
        thirdAccordionArrow = true;
        return("engineering-support");
      }
      else if  (window.location.search == "?name=business-support" )
      {
        firstAccordionArrow = false;
        fourthAccordionArrow = true;
        return("business-support");
      }
     else //if its default
     {
      return("logistics-expertise");
     }    
    }
    return (
      <div>
        <QueryParamsDemo />  
        <div className="bodyContainer">
          <Row>
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={DistributionImg}
                    className="img-responsive"
                    id="servicesImg"
                    alt="Nikkiso Machine"
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <div id="spacer">
                  <br />
                  <br />
                  <h1 className="productshead">
                    Distribution & Logistics Services
                  </h1>
                  <p id="servicesHeaderText">
                    Prominent in the industry for over 25 years, our
                    distribution and logistics services are a successful and
                    well-oiled machine that you can place your trust in.{" "}
                  </p>

                  <Button
                    variant="custom"
                    onClick={() =>
                      scrollToComponent(this.DistributionServices, {
                        offset: 0,
                        align: "top",
                        duration: 1500
                      })
                    }
                  >
                    Learn More
                  </Button>
                  </div>

                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner id="landingBannerServices" text={ServicesText} />
          </Row>
          <Row
            id="serviceTabContainer"
            ref={section => {
              this.DistributionServices = section;
            }}

          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/*Accordion Start */}
            <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
              className="flex-column flex-sm-row"
              id="accordion"
            >
              {/* Accordion 1 */}
              <Card title="Logistics Expertise" id="firstTab" >
                <Card.Header className="accordionHeader">
              <Link to="/hsmanufacturing/distribution-logistics?name=logistics-expertise">
                <CustomToggle eventKey={"logistics-expertise"} inputVariable={firstAccordionArrow}>
                <b>Logistics Expertise</b>
                </CustomToggle>
                </Link>
                </Card.Header>

                <Accordion.Collapse eventKey={"logistics-expertise"}>
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We offer the complete package, from start to finish.  It
                      doesn’t matter what size project – or where you are
                      located; NIPRO Technical Solutions provides the
                      strategic flow along with a supreme level of service. Let
                      us do the work of determining your national and
                      international logistic needs.  
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img src={Logistics} className="img-responsive tabImg" alt="H &S Warehouse" />
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 2 */}
              <Card title="Distribution Services" id="secondTab">
              <Card.Header className="accordionHeader">
                <Link to="/hsmanufacturing/distribution-logistics?name=distribution-services">
                  <CustomToggle eventKey={"distribution-services"} inputVariable={secondAccordionArrow}>
                  <b>Distribution Services</b>
                  </CustomToggle>
                </Link>
              </Card.Header>
                <Accordion.Collapse eventKey={"distribution-services"}>
                  <Card.Body>

              
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={ArtShipping}
                      className="img-responsive tabImg"
                      alt="H & S Distribution"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We provide timely domestic and international
                      transportation services, air-conditioned warehouse storage
                      space, and an exceptional fulfillment center. When you
                      partner with NIPRO Technical Solutions, you can
                      rest assured that the level of service provided will go
                      beyond the rest, getting the job done right and on time.
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 3 */}
              <Card title="Engineering Support" id="thirdTab">
                <Card.Header className="accordionHeader">
              <Link to="/hsmanufacturing/distribution-logistics?name=engineering-support">
              <CustomToggle eventKey={"engineering-support"} inputVariable={thirdAccordionArrow}>
              <b>Engineering Support</b>
                </CustomToggle >
                </Link>
                </Card.Header>
                <Accordion.Collapse eventKey={"engineering-support"} >
                  <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={Support2020}
                      className="img-responsive tabImg"
                      alt="H & S Training"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Our experience with the FDA and importing to the United
                      States can help streamline the process to get your product
                      to the end user, adding in any upgrades that are needed
                      for the US market.
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 4 */}
              <Card title="Business Support" id="fourthTab">
                <Card.Header className="accordionHeader">
              <Link to="/hsmanufacturing/distribution-logistics?name=business-support">
              <CustomToggle eventKey={"business-support"} inputVariable={fourthAccordionArrow}>
              <b>Business Support</b>
                </CustomToggle >
                </Link>
                </Card.Header>
                <Accordion.Collapse eventKey={"business-support"} >
                  <Card.Body>

                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img src={Business} className="img-responsive tabImg"
                    alt=" Business Support"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Benefit from our renowned Customer Service Department. Let
                      us provide your own unique toll-free number to meet all of
                      your customers' needs in the US. Each number has a
                      distinct ring and will be answered with your company
                      name.  
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
          </Row>
          <LandingBanner />
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default DistributionLogistics;
