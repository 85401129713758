import React, { Component } from "react";
import ContactForm from "./contact-form";
import Footer from "./footer";
class ContactPage extends Component {
  render() {
    return (
      <div>
        <br></br>
        <div className="jumbotron contactJumbotron">
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactPage;
