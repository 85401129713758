import React, { Component, useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";


// A custom hook that builds on useLocation to parse
// the query string for you.
//I did not make useQuery and QueryParamsDemo myself,
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  //runs the child function after getting the query string
  function QueryParamsDemo() {
    let query = useQuery();
    return (  
      <div>
          <Child name={query.get("name")} /> 
      </div>
    );
  }
//global variable for child function
var incrementer = 0;

  //what this does is take the query string, and automatically scroll to the corresponding element
//uses an interval to check until condition is reached
//its not the cleanest solution but none of the others were working for me
//incrementer is to stop it from running multiple times
//configured for use for hsm-products, hsm-services, tech-support, field-service-engineering, and hsm-meters-calibration-solutions
function Child({ name }) {
    //services has 4, no adjustments
    //techsupport has 2, no adjustments
    //products has 5, no adjustments
    //meters-calibrations-solutions has 3, no adjustments
    //field service has 3, no adjustments
    //quality-systems has 3
    //distribution-logistics has 4

    //at least 1
      if ((name == "genius" || name == "repair-program" || name == "calibration-center" 
      || name == "perfusion-equipment-maintenance" || name == "technical-support" || name =="quality-policy-statement" || name=="logistics-expertise") 
      && incrementer == 0)
      {
        const id = setInterval(function(){
          if (document.getElementById('firstTab')){
            //scroll to the element
          const newDiv = document.getElementById('firstTab');
          newDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });

      //manually adjust for the accordion tabs that are too big
      if(name == "genius" || name == "perfusion-equipment-maintenance"){
        setTimeout(() => {
          newDiv.scrollTop = newDiv.scrollTop - 100;
          newDiv.scrollIntoView({ block: 'start' });
  
          window.scrollBy(0,-150)
          }, 1);
        }
        else
        {
          newDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
          //reset the interval (so we aren't continuously checking)
            clearInterval(id);
          }
        }, 1000)
        incrementer += 1;
      }

      //at least 2
      else if ((name == "bta-612" || name == "exchange-program" || name == "calibration-reference-solutions" 
      || name == "dialysis-equipment-services" || name == "customer-service" || name=="quality-culture" || name=="distribution-services") && incrementer == 0)
      {
        const id = setInterval(function(){
          if (document.getElementById('secondTab')){
          const newDiv = document.getElementById('secondTab');
          

                      //these tabs are so big that we need to re-adjust
      //scrollBy won't run if theres no timeout (i dont know why)
      if(name == "bta-612" ||  name == "dialysis-equipment-services"){
        setTimeout(() => {
          newDiv.scrollTop = newDiv.scrollTop - 100;
          newDiv.scrollIntoView({ block: 'start' });
  
          window.scrollBy(0,-150)
          }, 1);
        }
        else
        {
          newDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
          //reset the interval (so we aren't continuously checking) 
            clearInterval(id);
          }
        }, 1000)
        incrementer += 1;
      }

      //at least 3
      else if ((name == "motor-shield" ||name == "recycle-program" ||name == "reference-meters" ||name == "engineering-team-leads" || name=="hs-certifications" || name=="engineering-support") && incrementer == 0)
      {
        const id = setInterval(function(){
          if (document.getElementById('thirdTab')){
          const newDiv = document.getElementById('thirdTab');
          
      //these tabs are so big that we need to re-adjust
      //scrollBy won't run if theres no timeout (i dont know why)
      if(name == "motor-shield" || name == "engineering-team-leads"){
        setTimeout(() => {
          newDiv.scrollTop = newDiv.scrollTop - 100;
          newDiv.scrollIntoView({ block: 'start' });
  
          window.scrollBy(0,-150)
          }, 1);
        }
        else
        {
          newDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

          //reset the interval (so we aren't continuously checking)      
            clearInterval(id);
          } 
        }, 1000)
        incrementer += 1;
      }

      //at least 4
      else if ((name == "valve-height-adjustment"|| name == "water-treatment-service-center" || name=="business-support") && incrementer == 0)
      {
        const id = setInterval(function(){
          if (document.getElementById('fourthTab')){
          const newDiv = document.getElementById('fourthTab');


      //these tabs are so big that we need to re-adjust
      //scrollBy won't run if theres no timeout (i dont know why)
      if(name == "valve-height-adjustment"){
      setTimeout(() => {
        newDiv.scrollTop = newDiv.scrollTop - 100;
        newDiv.scrollIntoView({ block: 'start' });

        window.scrollBy(0,-150)
        }, 1);
      }
      else
      {
        newDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
          //reset the interval (so we aren't continuously checking)      
            clearInterval(id);
          } 
        }, 1000)
        incrementer += 1;
      }

      //at least 5
      else if (name == "valve-repair-kits" && incrementer == 0)
  {
    const id = setInterval(function(){
      if (document.getElementById('fifthTab')){
      const newDiv = document.getElementById('fifthTab');
      newDiv.scrollTop = newDiv.scrollTop - 100;
      newDiv.scrollIntoView({ block: 'start' });
      //these tabs are so big that we need to re-adjust
      //scrollBy won't run if theres no timeout (i dont know why)
      //no condiitonal needed since this is the only one
      setTimeout(() => {
        window.scrollBy(0,-150)
        }, 1);
      
      //reset the interval (so we aren't continuously checking)     
        clearInterval(id);
      } 
    }, 500)
    incrementer += 1;
  }
      else //the target is blank, don't jump
      {
        incrementer += 1;
      }
      //it gets mad if theres no return
      return (
        <div></div>
      );
    }

    export default QueryParamsDemo;