import React from "react";
import { Modal } from "react-bootstrap";

export const EmailModal = (props) => {
  return (
    <Modal show={props.showModal} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>Join our email list!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="l-center-container">
          <div>
            <div className="sc-bdVaJa iIDDUy">
              <div>
                <form
                  className="js-cm-form"
                  id="subForm"
                  action="https://www.createsend.com/t/subscribeerror?description="
                  method="post"
                  data-id="2BE4EF332AA2E32596E38B640E905619F5F804A0D90B9D698FCE1B372A1941E3EBB8D5497DEB689A588F9065333E305B6888A5D73608EE655270EBCECEFE2C6A"
                >
                  <div size="base" className="sc-jzJRlG bMslyb">
                    <div size="small" className="sc-jzJRlG liOVdz">
                      <div>
                        <label
                          size="14px"
                          color="#010101"
                          className="sc-gzVnrw golxPc"
                        >
                          Name <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                          aria-label="Name"
                          id="fieldName"
                          maxLength="200"
                          name="cm-name"
                          required
                          className="sc-iwsKbI iMsgpL"
                        />
                      </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                      <div>
                        <label
                          size="14px"
                          color="#010101"
                          className="sc-gzVnrw golxPc"
                        >
                          Email <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                          autoComplete="Email"
                          aria-label="Email"
                          id="fieldEmail"
                          maxLength="200"
                          name="cm-jrjyuyr-jrjyuyr"
                          required
                          type="email"
                          className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                        />
                      </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                      <div>
                        <label
                          size="14px"
                          color="#010101"
                          className="sc-gzVnrw golxPc"
                        >
                          Company <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                          aria-label="Company"
                          id="fieldjdulddi"
                          maxLength="200"
                          name="cm-f-jdulddi"
                          required
                          className="sc-iwsKbI iMsgpL"
                        />
                      </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                      <div>
                        <label
                          size="14px"
                          color="#010101"
                          className="sc-gzVnrw golxPc"
                        >
                          Title <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                          aria-label="Title"
                          id="fieldjdulddh"
                          maxLength="200"
                          name="cm-f-jdulddh"
                          required
                          className="sc-iwsKbI iMsgpL"
                        />
                      </div>
                    </div>
                    <div size="small" className="sc-jzJRlG liOVdz">
                      <div>
                        <label
                          size="14px"
                          color="#010101"
                          className="sc-gzVnrw golxPc"
                        >
                          Machine Type{" "}
                          <span className="sc-dnqmqq iFTUZ">*</span>
                        </label>
                        <input
                          aria-label="Machine Type"
                          id="fieldjdulddu"
                          maxLength="200"
                          name="cm-f-jdulddu"
                          required
                          className="sc-iwsKbI iMsgpL"
                        />
                      </div>
                    </div>
                    <div size="base" className="sc-jzJRlG bMslyb"></div>
                  </div>
                  <button
                    size="1rem"
                    color="#fff"
                    type="submit"
                    className="js-cm-submit-button sc-jKJlTe fwPvIT"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
