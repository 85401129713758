import React, { Component, useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {
  Row,
  Tabs,
  Tab,
  Col,
  Card,
  Image,
  Alert,
  Accordion,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import CharlesHeadshot from "./images/charlie-johnson-headshot.jpg";
import MarshallHeadshot from "./images/marshallHeadshot.jpg";
import EricHeadshot from "./images/DeraneyEric_headshot.JPG";

import LandingBanner from "./landing-banner";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import DialysisMachine from "./images/DialysisMachineRow.JPG";
import QueryParamsDemo from "./scrollerComponent.js";




const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}


const FieldText =
  "Complete on-site preventative maintenance services for your operating room equipment!";
class FieldServiceEngineering extends Component {
  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    
    //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=perfusion-equipment-maintenance" )
      {
        return("perfusion-equipment-maintenance");
      }
      else if  (window.location.search == "?name=dialysis-equipment-services" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        
        return("dialysis-equipment-services");
      }
     else //if its default
     {
      return("perfusion-equipment-maintenance");
     }    
    }

 
    return (
      <div>
        <QueryParamsDemo />   
        <div className="bodyContainer">
          <LandingBanner
            id="fieldServicelandingBannerServices"
            text={FieldText}
          />
          <Row>
            <div class="jumbotron" id="jumbotronNoImage">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div id="spacingDiv" className="fieldservicesheaderp">
                    <br />
                    <h1 className="productshead">
                    Field
                      Service Engineering
                    </h1>
                    <p id="servicesHeaderText">
                    NIPRO Technical Solutions{" "}
                      proudly provides an extensive
                      variety of preventative and corrective maintenance service
                      options to maintain the life of your capital equipment and
                      keep your facilities operations running efficiently. {" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner />
          </Row>
          <Row
            id="serviceTabContainer"
            ref={(section) => {
              this.Services = section;
            }}
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/*Accordion Start */}
            <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
              className="flex-column flex-sm-row"
              id="accordion"
            >
              {/* Accordion 1 */}
              <Card title="Perfusion Equipment Maintenance" id="firstTab" >
                <Card.Header className="accordionHeader">
              <Link to="/field-service-engineering?name=perfusion-equipment-maintenance">
                <CustomToggle eventKey={"perfusion-equipment-maintenance"} inputVariable={firstAccordionArrow}>
                <b>Perfusion Equipment Maintenance</b>
                </CustomToggle>
                </Link>
                </Card.Header>

                <Accordion.Collapse eventKey={"perfusion-equipment-maintenance"}>
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We proudly provide preventative and corrective maintenance
                      services to extend the life of critical lifesaving
                      equipment; keeping your facilities operations running
                      efficiently. On-site services are performed by our
                      certified field service engineers with the advantage of
                      competitive, flat rate pricing to help keep your facility
                      costs down.
                      <br />
                      <br />
                      Featured Complete On-Site Maintenance Services: 
                      <br />
                      <br />
                    </p>

                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>PERFUSION EQUIPMENT</Alert.Heading>
                          <p className="alertText">
                            Stӧckert® SIII & S5
                            <br />
                            Stӧckert® Centrifugal Pump (SCP) 
                            <br />
                            Stӧckert® Centrifugal Pump Consoles (SCPC)
                            <br />
                            Medtronic 540E & 550 Bio Consoles
                            <br />
                            Quest Medical MPS® 1 & 2
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>HEATER/COOLERS</Alert.Heading>
                          <p className="alertText">
                            Stӧckert® 3T
                            <br />
                            Cincinnati Sub-Zero Hemotherm
                            <br />
                            Sarns&trade;
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>BLOOD SALVAGE SYSTEMS</Alert.Heading>
                          <p className="alertText">
                            Sorin Group XTRA
                            <br />
                            COBE® Brat 2
                          </p>
                        </Alert>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Alert className="valuesText" variant="secondary">
                          <Alert.Heading>EXCLUSIVE SERVICES</Alert.Heading>
                          <p className="alertText">
                            Century® HLM
                            <br />
                            Century® HLM Pumps
                          </p>
                        </Alert>
                      </Col>
                       
                    </Row>

                    <br />
                    <p>
                      <i>
                        Don’t see your equipment listed? Give us as call or{" "}
                        <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUNjI2MlpMN0pPVVZWVE5RNElHR0JXNldGNi4u">contact us</a> . Our specially
                        trained field service representatives are standing by to
                        assist you.
                      </i>
                    </p>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 2 */}
              <Card title="Dialysis Equipment Services" id="secondTab">
              <Card.Header className="accordionHeader">
                <Link to="/field-service-engineering?name=dialysis-equipment-services">
                  <CustomToggle eventKey={"dialysis-equipment-services"} inputVariable={secondAccordionArrow}>
                  <b>Dialysis Equipment Services</b>
                  </CustomToggle>
                </Link>
              </Card.Header>
                <Accordion.Collapse eventKey={"dialysis-equipment-services"}>
                  <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                    NIPRO Technical Solutions performs
                      on-site installation, repairs and preventative maintenance
                      services for dialysis equipment across North America. We
                      provide one-time visit or contracted services options. Our
                      in-house training lab and expert technicians are available
                      to elevate your biomed team.
                      <br />
                      <br />
                      Our benchmark quality standards will keep your dialysis
                      equipment running smoothly and maintaining compliance for
                      years to come, all while saving your facility’s team the
                      precious time and valuable resources required to maintain
                      quality of care for your patients.
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={DialysisMachine}
                      className="img-responsive tabImg"
                      alt="Dialysis Equipment"
                    />
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* Accordion 3 */}

            </Accordion>
            </div>
          </Row>

          <LandingBanner text="Our field service engineers are the technical experts you need." />
          <div id="contact">
            <ContactForm addressOverride="service@niprotechnical.com" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FieldServiceEngineering;
